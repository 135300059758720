<template>
  <v-row no-gutters>
    <v-col cols="6">
      <avatar :initialWidth="avatarWidth"></avatar>
    </v-col>
    <v-col cols="6" align-self="center">
      <v-row align="center" justify="center">
        <v-col align="center" cols="12">
          <span class="text-h3">
            {{ statusHeader }}
          </span>
        </v-col>
        <v-col cols="8" align="center">
          <v-progress-linear color="success" :indeterminate="intermediate" value="100" rounded class="mb-2" height="15">
          </v-progress-linear>
          <span class="text-h5">
            {{ status }}
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" align="end" class="px-15">
      <span class="px-5 text-h6">
        {{ location }}
      </span>
      <v-icon @click="$router.push('/rec')" class="mr-10" size="50" color="grey lighten-1">
        mdi-cog-outline
      </v-icon>

      <v-icon @click="$store.dispatch('logout')" size="50" color="error lighten-2">
        mdi-power-standby
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar.vue";

import { mapGetters } from "vuex";

export default {
  name: "loadData",
  data() {
    return {
      statusHeader: "Fetching My Intelligence!",
      status: "Initiating..",
      intermediate: true,
      value: 0,
      location: "",
    };
  },
  components: {
    avatar,
  },
  computed: {
    ...mapGetters([
      "appDimensions",
      "getKioskProfile",
      "getAvatarLoaded",
      "getDataLoaded",
      "hasCamera",
      "hasMicrophone",
      "getAvatarDetails",
      "screenSaver",
      "getAvatarGreetingMsg"
    ]),
    avatarWidth() {
      return this.appDimensions.width / 2.1;
    },
  },
  watch: {
    getAvatarLoaded(newVal) {
      if (newVal) {
        if (!this.getDataLoaded) {
          this.initLoadData();
          this.location = this.getKioskProfile.data().name;
        }
      }
    },
  },
  methods: {
    initLoadData() {
      const URI = this.getKioskProfile.data().cmsLink;
      this.$store
        .dispatch("createApolloConnection", URI + "/graphql")
        .then((response) => {
          //this.$store.dispatch("pullAllQnA")
          this.status = response;
          this.$store.dispatch("fetchFloors").then((response) => {
            this.status = response;
            this.$store.dispatch("fetchMaps").then((response) => {
              this.status = response;
              this.$store.dispatch("fetchFaqs").then((response) => {
                this.status = response;
                this.$store.dispatch("fetchHomePage").then((response) => {
                  console.log('Default Location from firebase: ', this.getKioskProfile.data().defaultLocation)
                  let temp = this.$i18n.t("maps").filter(map => map.displayPosition.toString() === this.getKioskProfile.data().defaultLocation)
                  console.log('Default Location:', temp)
                  this.$store.commit("setDefaultLocation", temp[0].mapName)
                  //this.$store.dispatch("filePullScheduler")
                  //setTimeout(() => { this.$store.dispatch("reloadApplication") }, 60000)
                  this.status = response;
                  this.$store.commit("setDataLoaded", true);
                  this.status = "Data Loaded";
                  this.$store.commit("setEnableAllRecognition", true);
                  this.status = "Enabled All Recognition";
                  this.$store.commit("setEnableSpeechRecognition", true);
                  this.status = "Enabled Speech Recogniton";
                  this.$store.commit("setEnableFaceDetection", true);
                  this.status = "Enabled Face Detection";
                  this.intermediate = false;
                  this.status = "Fetch Complete!";
                  this.statusHeader = "Completed!";
                  setTimeout(() => {
                    this.$store.dispatch("setDefaults")
                    this.updateText();
                  }, 2000)
                });
              })
            });
          });
        });
      //   });
      // });
    },

    updateText() {
      if (this.screenSaver) {
        this.$router.push("/screenSaver");
      } else {
        this.$router.push("/");
      }

      this.$store.commit("setDefaultBubbleText", true);
      this.$store.commit(
        "setTextBubbleText", this.getAvatarGreetingMsg);
    },
  },
};
</script>
