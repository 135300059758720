import { ApolloClient } from "apollo-client";
import axios from "axios";
import { createHttpLink, HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import * as fb from "@/firebase/index.js";
import {
  floorQuery,
  allFormQuery,
  mapQuery,
  faqQuery,
  faqQuery2,
  faqCategoryQuery,
  formCategoryQuery,
  homepageQuery,
} from "@/graphql/query";

function today() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  return mm + "-" + dd + "-" + yyyy;
}

export default {
  state: {
    dataLoaded: false,
    apolloClient: undefined,
    homepage: [],
    floors: [],
    maps: new Map(),
    forms: [],
    faqs: [],
    faqCategory: [],
    formCategories: [],
    formTypes: [],
    formPackets: [],
    fileCache: null,
    count: 0,
    modules: [],
    languages: [],
    kioskLocation: "",
    subKioskLocation: "",
    courts: [],
    defaultLocation: "",
    avatarGreetingMsg: ""
  },
  getters: {
    getDataLoaded: (state) => state.dataLoaded,
    getModules: (state) => state.modules,
    getHomepage: (state) => state.homepage,
    getFloors: (state) => state.floors,
    getMaps: (state) => state.maps,
    getFaqs: (state) => state.faqs,
    getFaqCategories: (state) => state.faqCategory,
    getForms: (state) => state.forms,
    getFormCategories: (state) => state.formCategories,
    getFormType: (state) => state.formTypes,
    getFormPackets: (state) => state.formPackets,
    getCourtType: (state) => state.courtType,
    getLanguages: (state) => state.languages,
    getKioskLocation: (state) => state.kioskLocation,
    getSubKioskLocation: (state) => state.subKioskLocation,
    getCourts: (state) => state.courts,
    getDefaultLocation: (state) => state.defaultLocation,
    getAvatarGreetingMsg: state => state.avatarGreetingMsg
  },
  mutations: {
    setDataLoaded(state, status) {
      state.dataLoaded = status;
    },
    setApolloClient(state, uri) {
      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      });
      state.apolloClient = new ApolloClient({
        link: new HttpLink({ uri: uri }),
        cache: new InMemoryCache({ fragmentMatcher }),
      });
    },
    setModules(state, module) {
      state.modules.push(module);
    },
    emptyModules(state) {
      state.modules = [];
    },
    setLanguages(state, lang) {
      state.languages.push(lang);
    },
    emptyLanguages(state) {
      state.languages = [];
    },
    setkioskLocation(state, location) {
      state.kioskLocation = location;
    },
    setSubKioskLocation(state, location) {
      console.log('Kiosk Sub Location : ', location)
      state.subKioskLocation = location;
    },
    setHomepage(state, page) {
      console.log('Loading Home Pages: ', page)
      state.homepage.push(page);
    },
    emptyHomepage(state) {
      state.homepage = [];
    },
    setFloors(state, floor) {
      state.floors.push(floor);
    },
    setMaps(state, map) {
      state.maps.set(map.mapName.toLowerCase(), map);
    },
    setForms(state, form) {
      state.forms.push(form);
    },
    setFaqs(state, faq) {
      state.faqs.push(faq);
    },
    setFaqCategory(state, category) {
      state.faqCategory.push(category);
    },
    setFormCategories(state, category) {
      state.formCategories.push(category);
    },
    setFormTypes(state, types) {
      state.formTypes = types;
    },
    setFormPackets(state, packet) {
      state.formPackets.push(packet);
    },
    setCourts(state, court) {
      state.courts.push(court);
    },
    setDefaultLocation(state, location) {
      state.defaultLocation = location;
    },
    setAvatarGreetingMsg(state, msg){
      state.avatarGreetingMsg  = msg
    }
  },
  actions: {
    createApolloConnection({ commit }, uri) {
      return new Promise((resolve, reject) => {
        if (uri === "" || uri === null) reject("Empty or Invalid URI");
        try {
          commit("setApolloClient", uri);
          resolve("Apollo linked successfully");
        } catch (err) {
          reject(err.message);
        }
      });
    },
    initiateDataPull({ commit, dispatch }, kioskData) {
      console.log('Kiosk information AT INITiate kiosk data: ', kioskData.data())
      commit("setQnaEndpointEN", kioskData.data().englishQna);
      commit("setQnaEndpointES", kioskData.data().spanishQna);
      commit("setkioskLocation", kioskData.data().locationCode);
      commit("setSubKioskLocation", kioskData.data().subLocationCode);
      commit("setAvatarDetails", kioskData.data().avatarInfo);
      commit("setAvatarGreetingMsg", kioskData.data().avatarGreetingMsg)
      dispatch("indentifyModuleLang", {
        module: kioskData.data().modules,
        language: kioskData.data().languages,
      });
      console.log("kioskData.data().defaultLocation", kioskData.data().defaultLocation)
      dispatch("identifyAvatarLangCodes", "en");
      //dispatch('updateAutoReferesh', kioskData)
    },
    indentifyModuleLang({ commit, dispatch }, data) {
      commit("emptyModules");
      commit("emptyLanguages");
      data.module.forEach((mod) => {
        commit("setModules", mod);
      });
      data.language.forEach((lang) => {
        commit("setLanguages", lang);
      });
      dispatch("loadAvatar");
    },
    fetchHomePage({ commit, dispatch, state, getters }) {
      return new Promise((resolve, reject) => {
        commit("emptyHomepage");
        console.log('Kiosk Sub Location : ', getters.getSubKioskLocation)
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: homepageQuery,
              variables: {
                location: getters.getSubKioskLocation,
                alias: getters.getModules,
                lang: lang,
              },
            })
            .then(
              (items) => {
                items.data.homepages.forEach((page) => {
                  dispatch("saveInCache", page.displayIcon.url).then(
                    (response) => {
                      page.displayIcon.url = response;
                      commit("setHomepage", page);
                    }
                  );
                });
                resolve("Homepage fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },
    // Fetch Floor from strapi
    fetchFloors({ commit, dispatch, state, getters }) {
      console.log('Kiosk Sub Location : ', getters.getSubKioskLocation)
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: floorQuery,
              variables: {
                location: getters.getSubKioskLocation,
                lang: lang,
              },
            })
            .then(
              (floors) => {
                floors.data.floors.forEach((floor) => {
                  dispatch("saveInCache", floor.floorImage.url).then(
                    (response) => {
                      floor.floorImage.url = response;
                      commit("setFloors", floor);
                    }
                  );
                });
                resolve("Floor data fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // Fetching Maps from Strapi
    fetchMaps({ commit, state, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: mapQuery,
              variables: {
                location: getters.getSubKioskLocation,
                lang: lang,
              },
            })
            .then(
              (maps) => {
                maps.data.maps.forEach((map) => {
                  dispatch("saveInCache", map.mapImage.url).then((response) => {
                    commit("setMaps", {
                      mapName: map.mapName,
                      mapFloor: map.mapFloor,
                      mapImage: response,
                      speech: map.speech,
                      locale: map.locale,
                      hearingLocationCode: map.hearingLocationCode,
                      displayPosition: map.displayPosition,
                    });
                  });
                });
                resolve("Map data fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // --------------------------------------------------------- Fetch Data related to Faqs Page -----------------------------------------

    fetchFaqs({ commit, state, getters, dispatch }) {
      console.log(
        "Kiosk location code at fetch faqs: ",
        getters.getSubKioskLocation
      );
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: faqQuery,
              variables: {
                location: getters.getSubKioskLocation,
                lang: lang,
              },
            })
            .then(
              (faqs) => {
                //commit('setFaqs', faqs.data.faqs);
                faqs.data.faqs.forEach((faq) => {
                  dispatch("fetchFaqCategory", faq.category);
                  let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, "<br />"),
                    category: faq.category,
                    displayType: "panel",
                    locale: faq.locale,
                    showMap: faq.showmap,
                    mapName: faq.mapName,
                    showQrCode: faq.showUrl,
                    qrInfo: faq.urlDetails,
                    linkId: faq.linkId,
                  };
                  commit("setFaqs", data);
                });
                //resolve('FAQs fetched successfully.')
              },
              (error) => {
                reject(error.message);
              }
            );

          state.apolloClient
            .query({
              query: faqQuery2,
              variables: {
                location: getters.getSubKioskLocation,
                lang: lang,
              },
            })
            .then(
              (faqs) => {
                faqs.data.faqs.forEach((faq) => {
                  dispatch("fetchFaqCategory", faq.category);
                  let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, "<br />"),
                    category: faq.category,
                    displayType: "panel",
                    locale: faq.locale,
                    showMap: faq.showmap,
                    mapName: faq.mapName,
                    showQrCode: faq.showUrl,
                    qrInfo: faq.urlDetails,
                    linkId: faq.linkId,
                  };
                  commit("setFaqs", data);
                });
                resolve("FAQs fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    fetchFaqCategory({ commit, state, getters }, faqCategory1) {
      return new Promise((response, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: faqCategoryQuery,
              variables: {
                location: getters.getSubKioskLocation,
                lang: lang,
                category: faqCategory1,
              },
            })
            .then(
              (faqCategory) => {
                faqCategory.data.faqCategories.forEach((category) => {
                  if (getters.getFaqCategories.length === 0) {
                    commit("setFaqCategory", category);
                  } else {
                    let temp = getters.getFaqCategories.filter(
                      (faqCat) => faqCat.categoryName === faqCategory1
                    );
                    if (temp.length === 0) {
                      commit("setFaqCategory", category);
                    } else if (temp.length === 1) {
                      if (temp[0].locale != category.locale) {
                        commit("setFaqCategory", category);
                        console.log("Faq Category: ", temp);
                      }
                    }
                  }
                });

                //response('FAQ categories fetched successfully')
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // --------------------------------------------------------- Fetch Data related to Forms Page -----------------------------------------

    fetchFormCategories({ commit, state, getters }) {
      return new Promise((response, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: formCategoryQuery,
              variables: {
                lang: lang,
              },
            })
            .then(
              (categories) => {
                categories.data.formCategories.forEach((category) => {
                  commit("setFormCategories", category);
                });
                response("Form categories fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    fetchForms({ commit, dispatch, state, getters }) {
      return new Promise((resolve, reject) => {
        getters.getLanguages.forEach((lang) => {
          state.apolloClient
            .query({
              query: allFormQuery,
              variables: {
                lang: lang,
              },
            })
            .then(
              (forms) => {
                forms.data.forms.forEach((form) => {
                  if (form.documentType !== "eforms") {
                    dispatch("saveInCache", form.document.url).then(
                      (response) => {
                        commit("setForms", {
                          formName: form.formName,
                          formCategory: form.formCategory,
                          documentType: form.documentType,
                          document: response,
                          documentUrl: form.documentUrl,
                          locale: form.locale,
                        });
                      }
                    );
                  } else {
                    commit("setForms", {
                      formName: form.formName,
                      formCategory: form.formCategory,
                      documentType: form.documentType,
                      document: null,
                      documentUrl: form.documentUrl,
                      locale: form.locale,
                    });
                  }
                });
                resolve("Form data fetched successfully.");
              },
              (error) => {
                reject(error.message);
              }
            );
        });
      });
    },

    // --------------------------------- Data Caching (Image, PDF) ------------------------------------------------------------
    saveInCache({ state, getters }, path) {
      return new Promise((response, reject) => {
        state.fileCache = caches.open("fileCache").then((cache) => {
          cache.match(getters.getCMSlink + path).then((cacheResponse) => {
            if (cacheResponse) {
              return cacheResponse.blob().then((blob) => {
                response(URL.createObjectURL(blob));
              });
            } else {
              cache.add(getters.getCMSlink + path);
              cache.match(getters.getCMSlink + path).then((cacheResponse) => {
                return cacheResponse.blob().then((blob) => {
                  response(URL.createObjectURL(blob));
                });
              });
            }
          });
        });
      });
    },

    // ---------------------------------------------- Odessey --------------------------------------------------------------

    searchOdysseybyName({ state }, keyword) {
      return new Promise((response, reject) => {
        fb.odysseyCollection
          .where("courtLocation", "==", "roswell")
          .where("dateCreated", "==", today())
          .where("partyOneName", "==", keyword.toLowerCase())
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty)
              reject("Sorry I did not find any results for this user name.");
            response(querySnapshot);
          });
      });
    },
    searchOdysseybyCaseNo({ state }, caseNumber) {
      return new Promise((response, reject) => {
        fb.odysseyCollection
          .where("courtLocation", "==", "roswell")
          .where("dateCreated", "==", today())
          .where("caseNo", "==", caseNumber)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty)
              reject("Sorry I did not find any results for this case number.");
            response(querySnapshot);
          });
      });
    },
    // searchByCaseNo({ state }, caseNumber) {
    //     return new Promise((response, reject) => {
    //         const xmlRequest = document.implementation.createDocument(null, 'Message')
    //         const messageNode = xmlRequest.documentElement
    //         messageNode.setAttribute('MessageType', 'FindCaseByCaseNumber');
    //         messageNode.setAttribute('NodeID', '1');
    //         messageNode.setAttribute('ReferenceNumber', 'ARS-1');
    //         messageNode.setAttribute('UserID', '1');
    //         messageNode.setAttribute('Source', 'Tyler');
    //         const caseNumberNode = xmlDocument.createElement('CaseNumber');
    //         caseNumberNode.textContent = caseNumber;
    //         messageNode.appendChild(caseNumberNode);
    //         const serializer = new XMLSerializer();
    //         const xmlString = serializer.serializeToString(xmlDocument);

    //         let data = qs.stringify({
    //             'siteKey': 'TXELPASOPROD2017',
    //             'odysseyMessageXML': xmlString
    //           });
    //         let config = {
    //             method: 'post',
    //             url: 'http://odyintegration/webservices/apiwebservice.asmx/OdysseyMsgExecution',
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             },
    //             data: data
    //         };

    //         axios(config)
    //             .then(response => {
    //                 console.log(JSON.stringify(response.data));
    //             })
    //             .catch(function (error) {
    //                 // console.log(error);
    //                 reject(error)
    //             });

    //     })
    // },

    addToLocalStorage({ getters }) {
      localStorage.setItem("floors", getters.getFloors);
      localStorage.setItem("maps", getters.getMaps);
      localStorage.setItem("faqs", getters.getFaqs);
      localStorage.setItem("formcategory", getters.getFormCategories);
      localStorage.setItem("forms", getters.getForms);
      localStorage.setItem("homepages", getters.getHomepage);
    },
  },
};
